import { useEffect, useRef, useState } from 'react';
import DropdownButton from '../../icons/dropdownBtn/DropdownButton';
import classes from './DropdownItem.module.scss';
import { DropdownItemModel } from './dropdown.model';

const DropdownItem = ({ item }: { item: DropdownItemModel }): JSX.Element => {
  const [isOpened, setIsopened] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const contentChildRef = useRef<HTMLDivElement>(null);

  const clickHandler = () => {
    setIsopened((prevState) => !prevState);
  };

  const resizeHandler = () => {
    const content = contentRef.current;
    const contentChild = contentChildRef.current;

    if (isOpened) {
      content!.style.height = `${contentChild?.clientHeight}px`;
    }
  };

  useEffect(() => {
    const content = contentRef.current;
    const contentChild = contentChildRef.current;

    if (isOpened) {
      content!.style.height = `${contentChild?.clientHeight}px`;
    } else {
      content!.style.height = '0px';
    }
  });

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, [isOpened]);

  return (
    <li
      className={`${classes['dropdown-item']} ${
        isOpened ? classes['opened'] : ''
      }`}
    >
      <span className={classes['heading']} onClick={clickHandler}>
        <div className={classes['title-subtitle__symbol']}>
          <div className={classes['title-subtitle']}>
            <h2 className={classes['title']}>{item.title}</h2>
            <h4 className={classes['subtitle']}>{item.subtitle}</h4>
          </div>

          <div className={classes['symbol']}>
            {typeof item.symbol === 'string' ? (
              <img src={item.symbol} alt='' />
            ) : (
              item.symbol
            )}
          </div>
        </div>

        <button className={classes['dropdown-btn']}>
          <DropdownButton />
        </button>
      </span>

      <div className={classes['content']} ref={contentRef}>
        <div className={classes['content-child']} ref={contentChildRef}>
          <div className={classes['desc__bg-img__container']}>
            <p className={classes['desc']}>
              {item.desc}
              <br />
              <br />
              <a
                href={item.link}
                target='_blank'
                className={classes['action']}
                rel='noreferrer'
              >
                {item['button-content'] || 'Explore'}
              </a>
            </p>
            <div className={classes['bg-img']}>{item.image}</div>
          </div>

          <a
            href={item.link}
            target='_blank'
            className={`${classes['action']} ${classes['outer']}`}
            rel='noreferrer'
          >
            {item['button-content'] || 'Explore'}
          </a>
        </div>
      </div>
    </li>
  );
};

export default DropdownItem;
