import classes from './Topaz.module.scss';

const Topaz = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.50013 0.686159L11.7001 2.53381L14.9 4.38123V11.7715L11.7001 13.6189L8.50013 15.4666L5.30011 13.6189L2.1001 11.7715V8.07635V4.38123L5.30011 2.53381L8.50013 0.686159Z'
        fill='#FF6600'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.50012 4.53028L10.273 6.30338L12.0461 8.0763L10.273 9.84922L8.50012 11.6223L6.72719 9.84922L4.9541 8.0763L6.72719 6.30338L8.50012 4.53028Z'
        fill='#FF9600'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.1001 4.38129L8.50013 4.5304L4.95411 8.07642L2.1001 4.38129Z'
        fill='#FFB100'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.1001 11.7715L8.50013 11.6224L4.95411 8.07636L2.1001 11.7715Z'
        fill='#FF6300'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.1001 4.38123V11.7715L4.95411 8.07636L2.1001 4.38123Z'
        fill='#FF8300'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9 4.38129L8.5 4.5304L12.046 8.07642L14.9 4.38129Z'
        fill='#FFB100'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9 11.7715L8.5 11.6224L12.046 8.07636L14.9 11.7715Z'
        fill='#E65900'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8999 4.38123V11.7715L12.0459 8.07636L14.8999 4.38123Z'
        fill='#FF8300'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.1001 4.38116L8.50013 4.53027V0.686092L2.1001 4.38116Z'
        fill='#FFCC00'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9 4.38116L8.5 4.53027V0.686092L14.9 4.38116Z'
        fill='#FFE331'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.1001 11.7715L8.50013 11.6224V15.4666L2.1001 11.7715Z'
        fill='#BA1200'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9 11.7715L8.5 11.6224V15.4666L14.9 11.7715Z'
        fill='#E63000'
      />
    </svg>
  );
};

export default Topaz;
