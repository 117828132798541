import classes from './FutureItem.module.scss';
import { FutureModel } from './future.model';

const FutureItem = ({
  futureData,
}: {
  futureData: FutureModel;
}): JSX.Element => {
  return (
    <li className={classes['future-item']}>
      <div className={classes['container']}>
        <div className={classes['pfp-title']}>
          <div className={classes['pfp-container']}>
            {futureData.pfp.map((pfp, idx) => {
              if (typeof pfp === 'string')
                return <img key={idx} src={pfp} alt={futureData.title} />;
              else return pfp;
            })}
          </div>

          <h2 className={classes['title']}>{futureData.title}</h2>
        </div>

        <p className={classes['desc']}>{futureData.desc}</p>
      </div>
    </li>
  );
};

export default FutureItem;
