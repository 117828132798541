import classes from './Echo.module.scss';

const Echo = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='22'
      viewBox='0 0 30 22'
      fill='none'
    >
      <g clipPath='url(#clip0_2090_422)'>
        <path
          d='M27.6191 10.72C27.6191 7.49548 26.6603 4.49771 25.0156 2.00326L26.874 0.514648C28.9466 3.73935 30.0241 7.51908 29.9648 11.3712C29.9495 12.369 29.8581 13.3596 29.6933 14.3346L27.3396 13.7053C27.523 12.7388 27.6191 11.7408 27.6191 10.72Z'
          fill='#361C19'
        />
        <path
          d='M21.006 5.215C21.7478 6.15952 22.3078 7.25693 22.6326 8.45299C23.9882 8.63922 25.033 9.81599 25.033 11.24C25.033 12.7482 23.8609 13.9792 22.3888 14.0494C22.0113 15.0735 21.4566 16.0101 20.7627 16.821L22.7022 18.6112C24.5564 16.5507 25.5845 13.8636 25.5854 11.0758C25.5863 8.37024 24.6196 5.75895 22.8691 3.72266L21.006 5.215Z'
          fill='#361C19'
        />
        <path
          d='M28.9913 17.1747C28.4507 18.8215 27.6914 20.3947 26.7281 21.8491L24.5503 20.1046C25.3561 19.0098 26.0245 17.805 26.5295 16.5166L28.9913 17.1747Z'
          fill='#361C19'
        />
        <path
          d='M7.36734 8.45299C7.69218 7.25693 8.2522 6.15953 8.9939 5.21501L7.13082 3.72266C5.38029 5.75895 4.41366 8.37024 4.41455 11.0758C4.41547 13.8636 5.44361 16.5507 7.29776 18.6112L9.23725 16.821C8.54329 16.0101 7.98863 15.0735 7.61115 14.0494C6.13912 13.9792 4.96698 12.7482 4.96698 11.24C4.96698 9.81599 6.01179 8.63922 7.36734 8.45299Z'
          fill='#361C19'
        />
        <path
          d='M4.98475 2.00326C3.34009 4.49771 2.38126 7.49548 2.38126 10.72C2.38126 11.7408 2.47734 12.7388 2.66083 13.7053L0.307076 14.3346C0.142281 13.3596 0.0508566 12.369 0.0355185 11.3712C-0.023688 7.51908 1.05383 3.73935 3.12636 0.514648L4.98475 2.00326Z'
          fill='#361C19'
        />
        <path
          d='M3.27197 21.8491C2.3087 20.3947 1.54937 18.8215 1.00879 17.1747L3.47054 16.5166C3.97555 17.805 4.64393 19.0098 5.44979 20.1046L3.27197 21.8491Z'
          fill='#361C19'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.5624 9.9317C20.5624 10.7009 20.293 11.4063 19.8445 11.9566C20.3014 12.4463 20.5624 13.0128 20.5624 13.6166C20.5624 15.4733 18.0941 16.9785 15.0494 16.9785C12.0046 16.9785 9.53636 15.4733 9.53636 13.6166C9.53636 13.0359 9.77783 12.4895 10.2029 12.0129C9.72627 11.4549 9.43799 10.7274 9.43799 9.93176C9.43799 8.17398 10.8449 6.74902 12.5805 6.74902C13.5538 6.74902 14.4237 7.19718 15.0002 7.90086C15.5766 7.19718 16.4465 6.74902 17.4199 6.74902C19.1554 6.74902 20.5624 8.17398 20.5624 9.9317ZM13.9585 9.93176C13.9585 10.7458 13.3071 11.4057 12.5033 11.4057C11.6996 11.4057 11.048 10.7458 11.048 9.93176C11.048 9.11771 11.6996 8.45781 12.5033 8.45781C13.3071 8.45781 13.9585 9.11771 13.9585 9.93176ZM17.4971 11.4057C18.3008 11.4057 18.9524 10.7458 18.9524 9.93176C18.9524 9.11771 18.3008 8.45781 17.4971 8.45781C16.6934 8.45781 16.0418 9.11771 16.0418 9.93176C16.0418 10.7458 16.6934 11.4057 17.4971 11.4057Z'
          fill='#361C19'
        />
      </g>
      <defs>
        <clipPath id='clip0_2090_422'>
          <rect
            width='30'
            height='21.3636'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Echo;
