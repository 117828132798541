import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './Nav.module.scss';
import Buy from './buy/Buy';
import Socials from './socials/Socials';
import Tools from './tools/Tools';

const { REACT_APP_ROOT } = process.env;

const Nav = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const navHighlighter = useRef<HTMLDivElement>(null);
  const [listIsOpened, setListIsOpened] = useState<boolean>(false);
  const [linkIndex, setLinksIndex] = useState<number>(0);

  const clickHandler = (
    event: React.MouseEvent<HTMLElement>,
    path?: string
  ) => {
    const activeEl = document.querySelector(`.${classes['active']}`);
    const newActiveEl = event.currentTarget;

    if (activeEl) activeEl.classList.remove(classes['active']);
    if (!newActiveEl.classList.contains(classes['active'])) {
      newActiveEl.classList.add(classes['active']);
    }

    if (path) {
      setListIsOpened(false);
      setLinksIndex(0);
      navigate(path);
    } else highlight();
  };

  const highlight = () => {
    console.log('resize');
    const activeEl: HTMLElement = document.querySelector(
      `.${classes['active']}`
    )!;

    if (!activeEl) return;
    const highlighter = navHighlighter.current!;

    highlighter.style.left = `${activeEl.offsetLeft}px`;
    highlighter.style.width = `${activeEl.clientWidth}px`;
    highlighter.style.height = `${activeEl.clientHeight}px`;
  };

  const isActive = (path: string) => {
    const isActive: boolean = path === location.pathname;

    return isActive
      ? `${classes['nav-item']} ${classes['active']}`
      : classes['nav-item'];
  };

  const isActiveLink = (idx: number) => {
    const isActive: boolean = idx === linkIndex && listIsOpened;

    return isActive
      ? `${classes['nav-item']} ${classes['active']}`
      : classes['nav-item'];
  };

  const openLinks = (e: React.MouseEvent<HTMLElement>, index: number) => {
    clickHandler(e);
    if (linkIndex === index && listIsOpened) {
      setListIsOpened(false);
      setLinksIndex(0);
      return;
    }
    if (!listIsOpened) setListIsOpened(true);
    if (linkIndex !== index) setLinksIndex(index);
  };

  useEffect(() => {
    highlight();
    window.addEventListener('load', highlight);
    window.addEventListener('resize', highlight);
    window.addEventListener('orientationchange', highlight);

    return () => {
      window.removeEventListener('load', highlight);
      window.removeEventListener('resize', highlight);
    };
  });

  return (
    <nav className={classes['nav']}>
      <ul
        className={`${classes['drop-list']} ${
          listIsOpened ? classes['opened'] : ''
        }`}
        style={{ left: `${linkIndex * -100}%` }}
      >
        <li className={classes['drop-item']}>
          <Tools />
        </li>
        <li className={classes['drop-item']}>
          <Buy />
        </li>
        <li className={classes['drop-item']}>
          <Socials />
        </li>
      </ul>

      <ul className={classes['nav-list']}>
        <div className={classes['active-wrapper']} ref={navHighlighter}></div>

        <li
          className={isActive(REACT_APP_ROOT!)}
          onClick={(e) => clickHandler(e, '.')}
        >
          Home
        </li>

        <li
          className={isActive(REACT_APP_ROOT + 'about')}
          onClick={(e) => clickHandler(e, REACT_APP_ROOT + 'about')}
        >
          About
        </li>

        <li className={isActiveLink(0)} onClick={(e) => openLinks(e, 0)}>
          Tools
        </li>

        <li className={isActiveLink(1)} onClick={(e) => openLinks(e, 1)}>
          Buy
        </li>

        <li className={isActiveLink(2)} onClick={(e) => openLinks(e, 2)}>
          Social
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
