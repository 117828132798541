import classes from './Footer.module.scss';

const Footer = (): JSX.Element => {
  return (
    <footer className={classes['footer']}>
      <h4 className={classes['title']}>
        Lets win together, Lets Monke, Lets vibe.
      </h4>

      <div className={classes['horizontal-line']}>
        <div className={classes['yellow']}></div>
        <div className={classes['orange']}></div>
        <div className={classes['purple']}></div>
        <div className={classes['green']}></div>
      </div>
    </footer>
  );
};

export default Footer;
