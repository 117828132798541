import Topaz from '../../icons/topaz/Topaz';
import WapalNav from '../../icons/wapalNav/WapalNav';
import classes from '../Drop.module.scss';

const Buy = (): JSX.Element => {
  return (
    <div className={classes['list-container']}>
      <ul className={classes['list']}>
        <li className={classes['item']}>
          <a
            href='https://www.topaz.so/collection/Aptos-Monkeys-f932dcb983'
            target='_blank'
            className={`${classes['link']} ${classes['yellow']}`}
            rel='noreferrer'
          >
            <div className={classes['icon']}>
              <Topaz />
            </div>
            <div className={classes['name']}>Topaz</div>
          </a>
        </li>

        <li className={classes['item']}>
          <a
            href='https://wapal.io/collection/Aptos-Monkeys'
            target='_blank'
            className={`${classes['link']} ${classes['purple']}`}
            rel='noreferrer'
          >
            <div className={classes['icon']}>
              <WapalNav />
            </div>
            <div className={classes['name']}>Wapal</div>
          </a>
        </li>
        <li className={classes['item']}>
          <a
              href='https://www.mercato.xyz/aptos/collection/aptos-monkeys?bottomTab=trades&tab=items'
              target='_blank'
              className={`${classes['link']} ${classes['purple']}`}
              rel='noreferrer'
          >
            <div className={classes['icon']}>
              <img src={"/mercato.png"} style={{width:'90%'}} />
            </div>
            <div className={classes['name']}>Mercato</div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Buy;
