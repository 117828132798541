import FutureItem from './FutureItem';
import classes from './FutureList.module.scss';
import { futureListData } from './future.model';

const FutureList = (): JSX.Element => {
  return (
    <ul className={classes['future-list']}>
      {futureListData.map((item) => (
        <FutureItem key={item.id} futureData={item} />
      ))}
    </ul>
  );
};

export default FutureList;
