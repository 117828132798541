import classes from './Holders.module.scss';
import monkey from '../../../assets/images/about/holders.webp';
import Articles from '../../../components/about/article/Articles';
import GrabCard from '../../../components/about/grab-card/GrabCard';

const HoldersSection = (): JSX.Element => {
  return (
    <>
      <div className={classes['horizontal-line']}></div>

      <div className={classes['img-container']}>
        <img
          src={monkey}
          loading='lazy'
          width={4000}
          height={4000}
          alt='Holders Monkeys'
        />
        <div className={classes['overlay']}></div>
        <h3 className={classes['title']}>Our Holders</h3>
      </div>

      <p className={classes['paragraph']}>
        Our holders are literally{' '}
        <span className={classes['red']}>Gigachads!</span> Here is a list of
        crazy stuff our <span className={classes['red']}>monke holders</span>{' '}
        have already built!
      </p>

      <Articles />

      <GrabCard />
    </>
  );
};

export default HoldersSection;
