import classes from './TwitterNav.module.scss';

const TwitterNav = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
    >
      <path
        d='M9.37216 6.97925L14.0868 1.33325H12.9695L8.87483 6.23525L5.6055 1.33325H1.8335L6.77883 8.74658L1.8335 14.6666H2.95083L7.27483 9.48992L10.7282 14.6666H14.5002L9.37216 6.97925ZM7.84083 8.81125L7.3395 8.07325L3.3535 2.19992H5.07016L8.2875 6.93992L8.78816 7.67792L12.9702 13.8393H11.2542L7.84083 8.81192'
        fill='#1DA1F2'
      />
    </svg>
  );
};

export default TwitterNav;
