import { useLocation } from 'react-router-dom';
import classes from './AboutNav.module.scss';

const { REACT_APP_ROOT } = process.env;

const AboutNav = (): JSX.Element => {
  const location = useLocation();

  const isActive = (hash: string) => {
    const active: boolean = hash === location.hash;

    return active
      ? `${classes['nav-link']} ${classes['active']}`
      : classes['nav-link'];
  };

  return (
    <nav id='about-nav' className={classes['nav']}>
      <h3 className={classes['title']}>Who are we?</h3>

      <ul className={classes['nav-list']}>
        <a
          href={REACT_APP_ROOT + 'about#what-sect'}
          className={isActive('#what-sect')}
        >
          <li className={classes['nav-item']}></li>
          <h3 className={`${classes['title']} ${classes['linked']}`}>
            Who are we?
          </h3>
        </a>
        <a
          href={REACT_APP_ROOT + 'about#holders-sect'}
          className={isActive('#holders-sect')}
        >
          <li className={classes['nav-item']}></li>
          <h3 className={`${classes['title']} ${classes['linked']}`}>
            Our Holders
          </h3>
        </a>
        <a
          href={REACT_APP_ROOT + 'about#ecosystem-sect'}
          className={isActive('#ecosystem-sect')}
        >
          <li className={classes['nav-item']}></li>
          <h3 className={`${classes['title']} ${classes['linked']}`}>
            Monkes Ecosystem
          </h3>
        </a>
        <a
          href={REACT_APP_ROOT + 'about#future-sect'}
          className={isActive('#future-sect')}
        >
          <li className={classes['nav-item']}></li>
          <h3 className={`${classes['title']} ${classes['linked']}`}>
            The Future
          </h3>
        </a>
      </ul>
    </nav>
  );
};

export default AboutNav;
