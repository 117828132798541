import Member from '../member/Member';
import classes from './Team.module.scss';
import { team } from './team.model';

const Team = (): JSX.Element => {
  return (
    <ul className={classes['team']}>
      {team.map((member) => (
        <Member key={member.id} data={member} />
      ))}
    </ul>
  );
};

export default Team;
