import classes from './Dropdown.module.scss';
import DropdownItem from './DropdownItem';
import { dropdown } from './dropdown.model';

const Dropdown = (): JSX.Element => {
  return (
    <ul className={classes['dropdown-list']}>
      {dropdown.map((item, idx) => (
        <DropdownItem key={item.id} item={item} />
      ))}
    </ul>
  );
};

export default Dropdown;
