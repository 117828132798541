import { Link } from 'react-router-dom';
import classes from './GrabCard.module.scss';

const GrabCard = (): JSX.Element => {
  return (
    <div className={classes['grab-container']}>
      <div className={classes['content']}>
        <h2 className={classes['heading']}>
          So, You Wanna Build Stuff on{' '}
          <span className={classes['red']}>Aptos?</span>
        </h2>

        <p className={classes['desc']}>
          Meet with cool builders in a community that will support!
        </p>
      </div>

      <Link to='https://wapal.io/collection/Aptos-Monkeys' target='_blank'>
        <button type='button' className={classes['grab-btn']}>
          Grab a Monke!
        </button>
      </Link>
    </div>
  );
};

export default GrabCard;
