import DiscordNav from '../../icons/discordNav/DiscordNav';
import TwitterNav from '../../icons/twitterNav/TwitterNav';
import classes from '../Drop.module.scss';

const Socials = (): JSX.Element => {
  return (
    <div className={classes['list-container']}>
      <ul className={classes['list']}>
        <li className={classes['item']}>
          <a
            href='https://x.com/AptosMonkeys?t=N9umKPFgMcaHJFpGmcPr-w&s=09'
            target='_blank'
            className={`${classes['link']} ${classes['blue']}`}
            rel='noreferrer'
          >
            <div className={classes['icon']}>
              <TwitterNav />
            </div>
            <div className={classes['name']}>X</div>
          </a>
        </li>

        <li className={classes['item']}>
          <a
            href='https://discord.gg/sFfe75BHQ3'
            target='_blank'
            className={`${classes['link']} ${classes['dark-blue']}`}
            rel='noreferrer'
          >
            <div className={classes['icon']}>
              <DiscordNav />
            </div>
            <div className={classes['name']}>Discord</div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socials;
