import classes from './DiscordNav.module.scss';

const DiscordNav = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M12.8469 3.55345C11.9602 3.14011 11.0002 2.84011 10.0002 2.66678C9.99147 2.6665 9.98275 2.66814 9.97469 2.6716C9.96663 2.67505 9.95942 2.68024 9.95357 2.68678C9.83357 2.90678 9.69357 3.19345 9.60024 3.41345C8.53957 3.25345 7.46091 3.25345 6.40024 3.41345C6.30691 3.18678 6.16691 2.90678 6.04024 2.68678C6.03357 2.67345 6.01357 2.66678 5.99357 2.66678C4.99357 2.84011 4.04024 3.14011 3.14691 3.55345C3.14024 3.55345 3.13357 3.56011 3.12691 3.56678C1.31357 6.28011 0.813574 8.92011 1.06024 11.5334C1.06024 11.5468 1.06691 11.5601 1.08024 11.5668C2.28024 12.4468 3.43357 12.9801 4.57357 13.3334C4.59357 13.3401 4.61357 13.3334 4.62024 13.3201C4.88691 12.9534 5.12691 12.5668 5.33357 12.1601C5.34691 12.1334 5.33357 12.1068 5.30691 12.1001C4.92691 11.9534 4.56691 11.7801 4.21357 11.5801C4.18691 11.5668 4.18691 11.5268 4.20691 11.5068C4.28024 11.4534 4.35357 11.3934 4.42691 11.3401C4.44024 11.3268 4.46024 11.3268 4.47357 11.3334C6.76691 12.3801 9.24024 12.3801 11.5069 11.3334C11.5202 11.3268 11.5402 11.3268 11.5536 11.3401C11.6269 11.4001 11.7002 11.4534 11.7736 11.5134C11.8002 11.5334 11.8002 11.5734 11.7669 11.5868C11.4202 11.7934 11.0536 11.9601 10.6736 12.1068C10.6469 12.1134 10.6402 12.1468 10.6469 12.1668C10.8602 12.5734 11.1002 12.9601 11.3602 13.3268C11.3802 13.3334 11.4002 13.3401 11.4202 13.3334C12.5669 12.9801 13.7202 12.4468 14.9202 11.5668C14.9336 11.5601 14.9402 11.5468 14.9402 11.5334C15.2336 8.51344 14.4536 5.89345 12.8736 3.56678C12.8669 3.56011 12.8602 3.55345 12.8469 3.55345ZM5.68024 9.94011C4.99357 9.94011 4.42024 9.30678 4.42024 8.52678C4.42024 7.74678 4.98024 7.11345 5.68024 7.11345C6.38691 7.11345 6.94691 7.75345 6.94024 8.52678C6.94024 9.30678 6.38024 9.94011 5.68024 9.94011ZM10.3269 9.94011C9.64024 9.94011 9.06691 9.30678 9.06691 8.52678C9.06691 7.74678 9.62691 7.11345 10.3269 7.11345C11.0336 7.11345 11.5936 7.75345 11.5869 8.52678C11.5869 9.30678 11.0336 9.94011 10.3269 9.94011Z'
        fill='#002BFF'
      />
    </svg>
  );
};

export default DiscordNav;
