import classes from './JungleNav.module.scss';

const JungleNav = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <g clipPath='url(#clip0_2160_175)'>
        <path
          d='M10.6665 3.33325L12.6665 5.33325L11.3332 5.99992L13.9998 8.66658L11.9998 9.33325L14.6665 11.9999H8.6665'
          stroke='#40BFB8'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 14V12'
          stroke='#40BFB8'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.33333 8.66658L4 7.33325'
          stroke='#40BFB8'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.3335 8.00008L6.66683 6.66675'
          stroke='#40BFB8'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.3335 13.9999V5.33325'
          stroke='#40BFB8'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.88274 10.6633C3.59105 10.6462 3.30665 10.5654 3.04954 10.4266C2.79243 10.2878 2.56886 10.0944 2.39454 9.85988C2.22023 9.62539 2.09941 9.35555 2.04059 9.06935C1.98177 8.78314 1.98637 8.48753 2.05407 8.20329C1.81213 8.00218 1.62108 7.7468 1.49647 7.45791C1.37185 7.16903 1.3172 6.85481 1.33695 6.54081C1.3567 6.22682 1.4503 5.92192 1.61013 5.65093C1.76996 5.37994 1.9915 5.1505 2.25674 4.98129C2.02432 4.5684 1.94604 4.0864 2.03585 3.62117C2.12565 3.15594 2.37771 2.7377 2.74712 2.44098C3.11652 2.14426 3.57927 1.98832 4.05292 2.00096C4.52657 2.01359 4.98035 2.19397 5.33341 2.50996C5.68655 2.19447 6.14018 2.01452 6.61356 2.00213C7.08694 1.98974 7.54936 2.14572 7.91852 2.44231C8.28768 2.7389 8.53962 3.15686 8.62951 3.6218C8.7194 4.08673 8.64141 4.56848 8.40941 4.98129C8.67481 5.15035 8.89652 5.3797 9.0565 5.65067C9.21648 5.92164 9.31019 6.22657 9.33001 6.54061C9.34983 6.85466 9.29519 7.16894 9.17055 7.45788C9.0459 7.74681 8.85478 8.00221 8.61274 8.20329C8.68272 8.49716 8.68523 8.80306 8.62007 9.09803C8.55491 9.393 8.42378 9.66937 8.23652 9.90641C8.04926 10.1435 7.81074 10.335 7.53887 10.4667C7.26699 10.5983 6.96882 10.6667 6.66674 10.6666H4.00007L3.88274 10.6633Z'
          stroke='#40BFB8'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2160_175'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JungleNav;
