import EchoNav from '../../icons/echoNav/EchoNav';
import JungleNav from '../../icons/jungleNav/JungleNav';
import RafflorNav from '../../icons/rafflorNav/RafflorNav';
import classes from '../Drop.module.scss';

const Tools = (): JSX.Element => {
  return (
    <div className={classes['list-container']}>
      <ul className={classes['list']}>
        <li className={classes['item']}>
          <a
            href='https://rafflor.aptosmonkeys.club'
            target='_blank'
            className={`${classes['link']} ${classes['yellow']}`}
            rel='noreferrer'
          >
            <div className={classes['icon']}>
              <RafflorNav />
            </div>
            <div className={classes['name']}>Rafflor</div>
          </a>
        </li>

        <li className={classes['item']}>
          <a
            href='https://echo.aptosmonkeys.club/'
            target='_blank'
            className={`${classes['link']} ${classes['purple']}`}
            rel='noreferrer'
          >
            <div className={classes['icon']}>
              <EchoNav />
            </div>
            <div className={classes['name']}>Echo</div>
          </a>
        </li>

        <li className={classes['item']}>
          <a
            href='https://jungle.aptosmonkeys.club'
            target='_blank'
            className={`${classes['link']} ${classes['green']}`}
            rel='noreferrer'
          >
            <div className={classes['icon']}>
              <JungleNav />
            </div>
            <div className={classes['name']}>Jungle</div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Tools;
