import classes from './Discord.module.scss';

const Discord = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='65'
      height='64'
      viewBox='0 0 65 64'
      fill='none'
    >
      <path
        d='M51.8867 14.2148C48.34 12.5614 44.5 11.3614 40.5 10.6681C40.4649 10.667 40.43 10.6736 40.3978 10.6874C40.3655 10.7012 40.3367 10.7219 40.3133 10.7481C39.8333 11.6281 39.2733 12.7748 38.9 13.6548C34.6573 13.0148 30.3427 13.0148 26.1 13.6548C25.7267 12.7481 25.1667 11.6281 24.66 10.7481C24.6333 10.6948 24.5533 10.6681 24.4733 10.6681C20.4733 11.3614 16.66 12.5614 13.0867 14.2148C13.06 14.2148 13.0333 14.2414 13.0067 14.2681C5.75332 25.1214 3.75332 35.6814 4.73999 46.1348C4.73999 46.1881 4.76665 46.2414 4.81999 46.2681C9.61999 49.7881 14.2333 51.9214 18.7933 53.3348C18.8733 53.3614 18.9533 53.3348 18.98 53.2814C20.0467 51.8148 21.0067 50.2681 21.8333 48.6414C21.8867 48.5348 21.8333 48.4281 21.7267 48.4014C20.2067 47.8148 18.7667 47.1214 17.3533 46.3214C17.2467 46.2681 17.2467 46.1081 17.3267 46.0281C17.62 45.8148 17.9133 45.5748 18.2067 45.3614C18.26 45.3081 18.34 45.3081 18.3933 45.3348C27.5667 49.5214 37.46 49.5214 46.5267 45.3348C46.58 45.3081 46.66 45.3081 46.7133 45.3614C47.0067 45.6014 47.3 45.8148 47.5933 46.0548C47.7 46.1348 47.7 46.2948 47.5666 46.3481C46.18 47.1748 44.7133 47.8414 43.1933 48.4281C43.0867 48.4548 43.06 48.5881 43.0867 48.6681C43.94 50.2948 44.9 51.8414 45.94 53.3081C46.02 53.3348 46.1 53.3614 46.18 53.3348C50.7666 51.9214 55.38 49.7881 60.18 46.2681C60.2333 46.2414 60.26 46.1881 60.26 46.1348C61.4333 34.0548 58.3133 23.5748 51.9933 14.2681C51.9666 14.2414 51.94 14.2148 51.8867 14.2148ZM23.22 39.7614C20.4733 39.7614 18.18 37.2281 18.18 34.1081C18.18 30.9881 20.42 28.4548 23.22 28.4548C26.0467 28.4548 28.2867 31.0148 28.26 34.1081C28.26 37.2281 26.02 39.7614 23.22 39.7614ZM41.8067 39.7614C39.06 39.7614 36.7667 37.2281 36.7667 34.1081C36.7667 30.9881 39.0067 28.4548 41.8067 28.4548C44.6333 28.4548 46.8733 31.0148 46.8466 34.1081C46.8466 37.2281 44.6333 39.7614 41.8067 39.7614Z'
        fill='#002BFF'
      />
    </svg>
  );
};

export default Discord;
