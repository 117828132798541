import classes from './Ligtening.module.scss';

const Lightening = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M2.5 9L9.5 1.5L8 7H13.5L6.5 14.5L8 9H2.5Z'
        fill='#FF786C'
        stroke='#F01400'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Lightening;
