import { Link } from 'react-router-dom';
import Lightening from '../../icons/lightening/Ligtening';
import classes from './Article.module.scss';
import { ArticleModel } from './articles.model';

const Article = ({ article }: { article: ArticleModel }): JSX.Element => {
  return (
    <div className={classes['img-container']}>
      <img src={article.image} alt={article.name} />
      <div className={classes['overlay']}></div>

      <div className={classes['content']}>
        <h3 className={classes['name']}>{article.name}</h3>

        <p className={classes['desc']}>{article.desc}</p>

        <div className={classes['icon-caterory__container']}>
          <div className={classes['icon']}>
            <Lightening />
          </div>
          <div className={classes['category-name']}>Category</div>
        </div>

        <div className={classes['category']}>{article.category}</div>

        <Link
          className={classes['explore-link']}
          to={article.link}
          target='_blank'
        >
          <button className={classes['explore-btn']}>Explore</button>
        </Link>
      </div>
    </div>
  );
};

export default Article;
