import classes from './TwitterIcon.module.scss';

const TwitterIcon = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
    >
      <path
        d='M11.59 8.724L17.4834 1.6665H16.0867L10.9684 7.794L6.88169 1.6665H2.16669L8.34835 10.9332L2.16669 18.3332H3.56335L8.96835 11.8623L13.285 18.3332H18L11.59 8.724ZM9.67585 11.014L9.04919 10.0915L4.06669 2.74984H6.21252L10.2342 8.67484L10.86 9.59734L16.0875 17.299H13.9425L9.67585 11.0148'
        fill='white'
      />
    </svg>
  );
};

export default TwitterIcon;
