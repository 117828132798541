import classes from './WapalNav.module.scss';

const WapalNav = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
    >
      <path
        d='M9.7281 8.7164L8.49995 7.48824L7.27186 8.7164C6.93071 9.05755 6.93071 9.61192 7.27186 9.95307L8.49995 11.1812L9.7281 9.95307C10.0693 9.61192 10.0693 9.05755 9.7281 8.7164ZM8.49995 0C4.08208 0 0.5 3.58208 0.5 7.99997C0.5 12.4179 4.08208 15.9999 8.49995 15.9999C12.9179 15.9999 16.5 12.4179 16.5 7.99997C16.5085 3.59061 12.9179 0 8.49995 0ZM14.3763 5.484L11.7494 10.7377L12.6108 12.3582C12.7302 12.58 12.6876 12.8529 12.5085 13.032L11.8347 13.7057C11.613 13.9274 11.2462 13.9274 11.0245 13.7057L8.49995 11.1812L5.97547 13.7057C5.75372 13.9274 5.38698 13.9274 5.16523 13.7057L4.49146 13.032C4.31236 12.8529 4.26972 12.58 4.38911 12.3582L5.25052 10.7377L2.62366 5.484C2.51279 5.26225 2.55543 4.99785 2.73453 4.82728L3.87739 3.6759C4.09914 3.45415 4.46587 3.45415 4.68762 3.6759L8.49995 7.48824L12.3123 3.6759C12.5341 3.45415 12.9008 3.45415 13.1226 3.6759L14.2654 4.81875C14.4445 4.99785 14.4872 5.26225 14.3763 5.484ZM9.7281 8.7164L8.49995 7.48824L7.27186 8.7164C6.93071 9.05755 6.93071 9.61192 7.27186 9.95307L8.49995 11.1812L9.7281 9.95307C10.0693 9.61192 10.0693 9.05755 9.7281 8.7164Z'
        fill='#8759FF'
      />
    </svg>
  );
};

export default WapalNav;
