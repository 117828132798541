import { Link } from 'react-router-dom';
import Logo from '../icons/logo/Logo';
import classes from './Header.module.scss';
import Nav from './Nav';

const Header = (): JSX.Element => {
  return (
    <header className={classes['header']}>
      <div className={classes['logo-btn__container']}>
        <Link to='.' className={classes['logo']}>
          <Logo />
        </Link>

        {/*<button*/}
        {/*  className={classes['connect-btn']}*/}
        {/*  type='button'*/}
        {/*  aria-label='Connect Button'*/}
        {/*>*/}
        {/*  Connect Wallet*/}
        {/*</button>*/}
      </div>

      <Nav />
    </header>
  );
};

export default Header;
