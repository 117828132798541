import classes from './Future.module.scss';
import intro from '../../../assets/images/about/future-intro.webp';
import FutureList from '../../../components/about/future-list/FutureList';

const FutureSection = (): JSX.Element => {
  return (
    <>
      <div className={classes['horizontal-line']}></div>

      <div className={classes['img-container']}>
        <img
          src={intro}
          loading='lazy'
          width={4000}
          height={4000}
          alt='Future Intro'
        />
        <div className={classes['overlay']}></div>
        <h3 className={classes['title']}>The Future...</h3>
      </div>

      <p className={classes['paragraph']}>
        So Monkes cooked the monkes ecosystem and you think they will stop here?
        Well, definitely not! Here’s what we have on our sight in 2024.
      </p>

      <FutureList />
    </>
  );
};

export default FutureSection;
