import classes from './Jungle.module.scss';

const Jungle = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='34'
      height='32'
      viewBox='0 0 34 32'
      fill='none'
    >
      <path
        d='M23.0002 5.49919L27.5001 9.99908L24.5002 11.499L30.5 17.4989L26.0001 18.9989L32 24.9987H18.5003M21.5003 29.4986V24.9987M11.0005 17.4989L8.00059 14.499M11.0005 15.9989L14.0004 12.999M11.0005 29.4986V9.99908M7.73676 21.9914C7.08048 21.953 6.4406 21.7712 5.86211 21.4589C5.28362 21.1466 4.78059 20.7113 4.3884 20.1838C3.9962 19.6562 3.72437 19.049 3.59202 18.4051C3.45968 17.7611 3.47003 17.096 3.62236 16.4565C3.078 16.004 2.64814 15.4294 2.36777 14.7795C2.0874 14.1295 1.96443 13.4225 2.00887 12.716C2.05331 12.0096 2.2639 11.3236 2.6235 10.7138C2.98311 10.1041 3.48158 9.58791 4.07835 9.2072C3.55542 8.2782 3.37929 7.19372 3.58135 6.14699C3.78341 5.10025 4.35053 4.15925 5.18167 3.49164C6.0128 2.82403 7.05397 2.47318 8.11965 2.5016C9.18533 2.53002 10.2063 2.93587 11.0007 3.64684C11.7952 2.937 12.8159 2.53211 13.881 2.50424C14.946 2.47637 15.9865 2.82732 16.817 3.49463C17.6476 4.16195 18.2145 5.10232 18.4167 6.1484C18.619 7.19448 18.4435 8.27838 17.9215 9.2072C18.5186 9.58757 19.0175 10.1036 19.3774 10.7133C19.7374 11.3229 19.9482 12.009 19.9928 12.7156C20.0374 13.4222 19.9145 14.1293 19.634 14.7794C19.3536 15.4294 18.9236 16.0041 18.379 16.4565C18.5365 17.1177 18.5421 17.8059 18.3955 18.4696C18.2489 19.1333 17.9539 19.7551 17.5325 20.2884C17.1112 20.8218 16.5746 21.2527 15.9628 21.549C15.3511 21.8452 14.6803 21.999 14.0006 21.9989H8.00075L7.73676 21.9914Z'
        stroke='#361C19'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Jungle;
