import gui from '../../../assets/images/about/holders-gui.webp';
import wapai from '../../../assets/images/about/holders-wapai.webp';
import robos from '../../../assets/images/about/holders-robos.webp';
import Vibrant from '../../../assets/images/about/holders-Vibrant.webp';
import Townesquare from '../../../assets/images/about/holders-Townesquare.webp';
import AptosCreatures from '../../../assets/images/about/holders-AptosCreatures.webp';

export interface ArticleModel {
  id: string;
  image: string;
  name: string;
  desc: string;
  category: string;
  link: string;
}

export const articles: ArticleModel[] = [
  {
    id: 'a1',
    name: 'GUI INU',
    image: gui,
    desc: 'The top community token on Aptos',
    category: 'Meme Coin',
    link: 'https://www.guiinu.com',
  },
  {
    id: 'a2',
    name: 'Wapal',
    image: wapai,
    desc: 'Some crazy shit marketplace for NFTs',
    category: 'NFT Marketplace',
    link: 'https://wapal.io',
  },
  {
    id: 'a3',
    name: 'AptoRobos',
    image: robos,
    desc: 'Some robot degen art that people like',
    category: 'NFT Project',
    link: 'https://wapal.io/collection/AptoRobos',
  },
  {
    id: 'a4',
    name: 'Vibrant X',
    image: Vibrant,
    desc: 'Crazy degen De-Fi aggregator on Aptos',
    category: 'De-Fi Aggregator',
    link: 'https://vibrantx.finance',
  },
  {
    id: 'a5',
    name: 'Townesquare',
    image: Townesquare,
    desc: 'A web3 “everything” app',
    category: 'Pending',
    link: 'https://www.townesquare.xyz',
  },
  {
    id: 'a6',
    name: 'Aptos Creatures',
    image: AptosCreatures,
    desc: 'Some of the craziest art on Aptos',
    category: 'NFT Project',
    link: 'https://wapal.io/collection/creatus',
  },
];
