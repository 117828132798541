import classes from './EchoNav.module.scss';

const EchoNav = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='10'
      viewBox='0 0 15 10'
      fill='none'
    >
      <g clipPath='url(#clip0_2160_272)'>
        <path
          d='M13.1088 4.87557C13.1088 3.44243 12.6826 2.11009 11.9517 1.00145L12.7776 0.339844C13.6987 1.77304 14.1776 3.45292 14.1513 5.16497C14.1445 5.60843 14.1039 6.04869 14.0306 6.48202L12.9845 6.20236C13.0661 5.7728 13.1088 5.32925 13.1088 4.87557Z'
          fill='#A29CCA'
        />
        <path
          d='M10.1697 2.42889C10.4994 2.84868 10.7483 3.33642 10.8926 3.86799C11.4951 3.95077 11.9594 4.47377 11.9594 5.10665C11.9594 5.77699 11.4385 6.32408 10.7842 6.35531C10.6165 6.81043 10.3699 7.2267 10.0615 7.58712L10.9235 8.38274C11.7476 7.46697 12.2045 6.27272 12.2049 5.03367C12.2053 3.83122 11.7757 2.67064 10.9977 1.76562L10.1697 2.42889Z'
          fill='#A29CCA'
        />
        <path
          d='M13.7184 7.74413C13.4781 8.47606 13.1407 9.17528 12.7125 9.82166L11.7446 9.04633C12.1028 8.55974 12.3998 8.02428 12.6243 7.45166L13.7184 7.74413Z'
          fill='#A29CCA'
        />
        <path
          d='M4.10776 3.86799C4.25213 3.33642 4.50103 2.84868 4.83068 2.42889L4.00264 1.76562C3.22463 2.67064 2.79501 3.83122 2.79541 5.03367C2.79582 6.27272 3.25277 7.46697 4.07684 8.38274L4.93883 7.58712C4.63041 7.2267 4.38389 6.81043 4.21612 6.35531C3.56188 6.32408 3.04093 5.77696 3.04093 5.10665C3.04093 4.47377 3.50529 3.95077 4.10776 3.86799Z'
          fill='#A29CCA'
        />
        <path
          d='M3.04883 1.00145C2.31787 2.11009 1.89172 3.44243 1.89172 4.87557C1.89172 5.32925 1.93443 5.77279 2.01598 6.20236L0.969866 6.48202C0.896624 6.04869 0.855991 5.60843 0.849174 5.16497C0.82286 3.45292 1.30176 1.77304 2.22288 0.339844L3.04883 1.00145Z'
          fill='#A29CCA'
        />
        <path
          d='M2.2876 9.82166C1.85948 9.17528 1.522 8.47606 1.28174 7.74413L2.37585 7.45166C2.6003 8.02428 2.89735 8.55974 3.25551 9.04633L2.2876 9.82166Z'
          fill='#A29CCA'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.97249 4.52487C9.97249 4.86675 9.85275 5.18027 9.65344 5.42481C9.8565 5.64247 9.97249 5.89425 9.97249 6.16259C9.97249 6.9878 8.87549 7.65679 7.52228 7.65679C6.16903 7.65679 5.07204 6.9878 5.07204 6.16259C5.07204 5.9045 5.17936 5.66167 5.36826 5.44983C5.15645 5.20185 5.02832 4.87851 5.02832 4.5249C5.02832 3.74366 5.65362 3.11035 6.42499 3.11035C6.85758 3.11035 7.24421 3.30953 7.5004 3.62228C7.75659 3.30953 8.14323 3.11035 8.57582 3.11035C9.34719 3.11035 9.97249 3.74366 9.97249 4.52487ZM7.03746 4.5249C7.03746 4.88668 6.7479 5.17996 6.39067 5.17996C6.03347 5.17996 5.74389 4.88668 5.74389 4.5249C5.74389 4.1631 6.03347 3.86981 6.39067 3.86981C6.7479 3.86981 7.03746 4.1631 7.03746 4.5249ZM8.61014 5.17996C8.96737 5.17996 9.25695 4.88668 9.25695 4.5249C9.25695 4.1631 8.96737 3.86981 8.61014 3.86981C8.25293 3.86981 7.96335 4.1631 7.96335 4.5249C7.96335 4.88668 8.25293 5.17996 8.61014 5.17996Z'
          fill='#A29CCA'
        />
      </g>
      <defs>
        <clipPath id='clip0_2160_272'>
          <rect
            width='13.3333'
            height='9.49495'
            fill='white'
            transform='translate(0.833496 0.333252)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EchoNav;
