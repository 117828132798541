import classes from './RafflorNav.module.scss';

const RafflorNav = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
    >
      <path
        d='M13.6685 14.528C13.6491 14.528 13.63 14.5307 13.6109 14.5307C11.6513 14.5307 9.69174 14.5311 7.73229 14.532C7.70787 14.5316 7.68379 14.5262 7.66153 14.5162C7.63927 14.5062 7.61932 14.4917 7.6029 14.4736C7.41192 14.2973 7.21795 14.1239 7.02648 13.9481C6.79551 13.7358 6.5663 13.5217 6.33584 13.3088C6.06464 13.0582 5.79337 12.8077 5.52201 12.5574C5.29477 12.347 5.06902 12.1352 4.84129 11.9251C4.40668 11.5241 3.97158 11.1235 3.53598 10.7233C3.46594 10.6587 3.39492 10.5954 3.32464 10.5311C3.31571 10.5216 3.30742 10.5116 3.2998 10.501C3.31371 10.4827 3.32464 10.4645 3.33979 10.4489C3.37091 10.4158 3.40253 10.3827 3.43465 10.3496C4.1772 9.60948 4.91967 8.86958 5.66206 8.12983C5.83491 7.95748 6.00644 7.78364 6.17664 7.60831C6.19782 7.58605 6.22351 7.56857 6.252 7.55705C6.28048 7.54553 6.31109 7.54023 6.34179 7.5415C6.77226 7.5425 7.20272 7.5425 7.63319 7.5415C7.83758 7.5415 8.04222 7.54325 8.24661 7.53853C8.86748 7.52437 9.45903 7.11634 9.7029 6.56079C9.94107 6.0179 9.91176 5.48197 9.61499 4.96665C9.52856 4.8164 9.40862 4.69173 9.28097 4.57327C9.06143 4.36987 8.80191 4.25191 8.51383 4.18883C8.39513 4.16448 8.27414 4.15308 8.15298 4.15481H3.30228V1.20669C3.31569 1.20445 3.32861 1.20197 3.34177 1.20023C3.34773 1.19986 3.35369 1.19986 3.35965 1.20023C5.01347 1.20023 6.66721 1.20073 8.32086 1.20172C8.80613 1.20172 9.27601 1.30106 9.73048 1.4672C10.6337 1.79651 11.3753 2.34536 11.938 3.1269C12.3251 3.66332 12.595 4.27517 12.7303 4.92269C12.809 5.30023 12.8368 5.68662 12.8129 6.07154C12.7966 6.41472 12.7447 6.75528 12.6582 7.08778C12.5072 7.64631 12.2485 8.15343 11.9082 8.61932C11.6106 9.02284 11.2482 9.37436 10.8359 9.65965C10.4188 9.95168 9.95839 10.1761 9.47145 10.3247C9.41681 10.3416 9.3597 10.351 9.30357 10.3642C9.29463 10.3662 9.28594 10.3699 9.26955 10.3751L13.6752 14.5133L13.6685 14.528Z'
        fill='#FBB21D'
      />
    </svg>
  );
};

export default RafflorNav;
