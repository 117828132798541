import classes from './Cancel.module.scss';

const Cancel = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='65'
      height='64'
      viewBox='0 0 65 64'
      fill='none'
    >
      <path
        d='M53.8337 18.428L46.0737 10.668L32.5003 24.2413L18.927 10.668L11.167 18.428L24.7403 32.0013L11.167 45.5746L18.927 53.3346L32.5003 39.7613L46.0737 53.3346L53.8337 45.5746L40.2603 32.0013L53.8337 18.428Z'
        fill='#ED2F2F'
      />
    </svg>
  );
};

export default Cancel;
