import classes from './Ecosystem.module.scss';
import ecosystem from '../../../assets/images/about/ecosystem-intro.webp';
import Dropdown from '../../../components/about/dropdown/Dropdown';

const EcosystemSection = (): JSX.Element => {
  return (
    <>
      <div className={classes['horizontal-line']}></div>

      <div className={classes['img-container']}>
        <img
          src={ecosystem}
          loading='lazy'
          width={4000}
          height={4000}
          alt='Ecosystem Monkeys'
        />
        <div className={classes['overlay']}></div>
        <h3 className={classes['title']}>The Monkes Ecosystem</h3>
      </div>

      <p className={classes['paragraph']}>
        In the past 2 years, we have been able to amass more than 3000 holders
        from different parts of the world, attended Hackathons to present
        AptosMonkeys and is now{' '}
        <span className={classes['purple']}>the biggest NFT</span>{' '}
        <span className={classes['purple']}>valuation on Aptos</span> (we read
        that market data from{' '}
        <a
          href='https://snype.io/collection/aptos-monkeys-mdbl3e/analytics'
          target='_blank'
          className={classes['link']}
          rel='noreferrer'
        >
          Snype
        </a>
        ).
        <br />
        <br />
        Now we’ve created an{' '}
        <span className={classes['purple']}>Ecosystem</span> for our holders!
      </p>

      <Dropdown />

      <p className={classes['paragraph']}>
        So Monkes cooked all the stuff above, you think they will stop here?
        Well, definitely not!
      </p>
    </>
  );
};

export default EcosystemSection;
