import classes from './Rafflor.module.scss';

const Rafflor = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        d='M27.8319 30.9911C27.7883 30.9911 27.7453 30.9972 27.7023 30.9972C23.2927 30.9972 18.8833 30.9981 14.4741 31C14.4191 30.9992 14.3649 30.9871 14.3149 30.9646C14.2648 30.942 14.2199 30.9093 14.1829 30.8687C13.7532 30.4719 13.3167 30.0818 12.8858 29.6862C12.3661 29.2084 11.8504 28.7267 11.3318 28.2477C10.7215 27.6837 10.1111 27.12 9.50045 26.5567C8.98912 26.0834 8.48113 25.6067 7.96868 25.1339C6.99072 24.2316 6.01163 23.3302 5.03144 22.4297C4.87385 22.2844 4.71403 22.1419 4.55588 21.9972C4.5358 21.9759 4.51713 21.9533 4.5 21.9296C4.53129 21.8882 4.55588 21.8474 4.58996 21.8122C4.66001 21.7377 4.73115 21.6632 4.80343 21.5887C6.47435 19.9233 8.14507 18.2584 9.81562 16.5938C10.2046 16.206 10.5905 15.8148 10.9735 15.4202C11.0212 15.3701 11.079 15.3308 11.1431 15.3049C11.2072 15.279 11.2761 15.267 11.3452 15.2699C12.3138 15.2722 13.2824 15.2722 14.2511 15.2699C14.711 15.2699 15.1715 15.2738 15.6314 15.2632C17.0285 15.2314 18.3596 14.3132 18.9084 13.0631C19.4443 11.8415 19.3784 10.6355 18.7106 9.47593C18.5161 9.13783 18.2462 8.85729 17.959 8.59073C17.465 8.13304 16.881 7.86759 16.2327 7.72565C15.9656 7.67086 15.6934 7.6452 15.4207 7.6491H4.50557V1.01516C4.53574 1.01013 4.56482 1.00454 4.59444 1.00063C4.60784 0.999791 4.62126 0.999791 4.63466 1.00063C8.35613 1.00063 12.0774 1.00175 15.7985 1.00398C16.8905 1.00398 17.9478 1.22752 18.9705 1.60138C21.0029 2.34239 22.6716 3.57742 23.9379 5.33607C24.8089 6.54314 25.4163 7.91993 25.7206 9.37701C25.8979 10.2266 25.9604 11.096 25.9067 11.9622C25.8698 12.7344 25.7532 13.5007 25.5585 14.2489C25.2188 15.5057 24.6365 16.6469 23.8709 17.6953C23.2011 18.6033 22.3857 19.3943 21.4578 20.0362C20.5194 20.6934 19.4833 21.1984 18.3876 21.5328C18.2646 21.5708 18.1361 21.592 18.0098 21.6216C17.9897 21.6261 17.9701 21.6345 17.9333 21.6462L27.847 30.9581L27.8319 30.9911Z'
        fill='#361C19'
      />
    </svg>
  );
};

export default Rafflor;
