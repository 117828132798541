import smith from '../../../assets/images/about/jungle-smith.webp';
import monkey from '../../../assets/images/about/artsi-monkey.webp';
import rookie from '../../../assets/images/about/nomadic-rookie.webp';

export interface MemberModel {
  id: string;
  name: string;
  position: string;
  image: string;
  twitter: string;
}

export const team: MemberModel[] = [
  {
    id: 'm1',
    name: 'Jungle Smith',
    position: 'Founder',
    image: smith,
    twitter: 'https://x.com/JungleSmith1?t=GTOMXFeCguGqwcodfTCGvQ&s=09',
  },
  {
    id: 'm2',
    name: 'Artsi Monkey',
    position: 'Artist',
    image: monkey,
    twitter: 'https://x.com/ArtsiMonkey?t=Uq9iLK78jpFXest6_8gbLg&s=09',
  },
  {
    id: 'm3',
    name: 'Nomadic Rookie',
    position: 'Project Manager',
    image: rookie,
    twitter: 'https://x.com/Nomadic_Rookie?t=pSQ0GardlFGP3Bjj-iH_fA&s=09',
  },
];
