import AptosWatermark from '../icons/aptosWatermark/AptosWatermark';
import classes from './SeasonCard.module.scss';

const SeasonCard = (): JSX.Element => {
  return (
    <div className={classes['card']}>
      <div className={classes['overlay']}></div>
      <div className={classes['container']}>
        <AptosWatermark />
      </div>
      <div className={classes['content']}>
        <div className={classes['desc']}>
          You think the fun stops here? Absolutely not! Just as we finished{' '}
          <span className={classes['bold']}>Season 2</span>, we will already
          have prepared for...
        </div>

        <h2 className={classes['title']}>SEASON 3</h2>

        <h4 className={classes['subtitle']}>Guaranteed hotness.</h4>
      </div>
    </div>
  );
};

export default SeasonCard;
