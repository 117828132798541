import upgrade from '../../../assets/images/about/what-collection-img.webp';
import pixel from '../../../assets/images/about/future-item-pixel.webp';
import petz from '../../../assets/images/about/future-item-petz.webp';
import Discord from '../../icons/discord/Discord';
import Cancel from '../../icons/cancel/Cancel';
import Jungle from '../../icons/jungle/Jungle';
import Rafflor from '../../icons/rafflor/Rafflor';
import Harbor from '../../icons/habor/Habor';

export interface FutureModel {
  id: string;
  title: string;
  pfp: JSX.Element[] | string[];
  desc: string;
}

export const futureListData: FutureModel[] = [
  {
    id: 'f1',
    title: 'Art Upgrade',
    pfp: [upgrade],
    desc: 'A new upgradeable sick as f#CK art for your monkeys that you can get with $SEEDZ. And yes you get a pet with it? What can you do with a pet the utility guys will ask? Nothing! Your pet is your pet!',
  },
  {
    id: 'f2',
    title: 'Discord Shutdown',
    pfp: [<Discord key={0} />, <Cancel key={1} />],
    desc: 'The free part of discord will be shut down. You either need a AptosMonkey or Pixel Monke to gain access',
  },
  {
    id: 'f3',
    title: 'Pixel Monkes',
    pfp: [pixel],
    desc: 'Wanna still chill out in our discord but miss out on the juicy part, then we have a solution! We’ll create cool pixel monkes that can be customised on our website! Can only get 1 per twitter account and can be used to get access!',
  },
  {
    id: 'f4',
    title: 'Petz',
    pfp: [petz],
    desc: 'Your new petz are gonna be the next IP hot thing on instagram! Watch us get new fans for your petz that can later be translated into stuff we are still thinking about',
  },
  {
    id: 'f5',
    title: 'Jungle v2.0',
    pfp: [<Jungle key={0} />],
    desc: 'Ah yes! Your favourite staking app?! Gonna get the craziest revamp to be able to use new tokens and NFT’s there with the sleekest UI! Why? Cause we cook!',
  },
  {
    id: 'f6',
    title: 'Rafflor Revamp',
    pfp: [<Rafflor key={0} />],
    desc: 'We gonna revamp the Sh#t out of Rafflor and add some cool games there for you to degen your $Seedz with!',
  },
  {
    id: 'f7',
    title: 'Echo is Now Harbor',
    pfp: [<Harbor key={0} />],
    desc: 'Echo has evolved to harbor. What was only a p2p community token platform now has a mega added feature; a token launchpad protocol! Create tokens with no code, do token presales, create token airdrops!',
  },
];
