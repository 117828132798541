import classes from './DropdownButton.module.scss';

const DropdownButton = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
    >
      <path d='M6 15.5L12 9.5L18 15.5' stroke='#361C19' strokeWidth='2' />
    </svg>
  );
};

export default DropdownButton;
