import CollectionCard from '../../../components/about/collection-card/CollectionCard';
import Team from '../../../components/about/team/Team';
import classes from './What.module.scss';
import vibe from '../../../assets/images/about/what-vibe-img.webp';

const WhatSection = (): JSX.Element => {
  return (
    <>
      <div className={classes['horizontal-line']}></div>

      <div className={classes['img-container']}>
        <img
          src={vibe}
          loading='lazy'
          width={4000}
          height={4000}
          alt='Vibing Monkeys'
        />
        <div className={classes['overlay']}></div>
        <h3 className={classes['title']}>What is AptosMonkeys?</h3>
      </div>

      <p className={classes['paragraph']}>
        AptosMonkeys is a{' '}
        <span className={classes['yellow']}>movement, cult, community</span>{' '}
        that was imagined to be cute, funny but at the same time to be{' '}
        <span className={classes['emphasis']}>pioneers</span>
        on a new move based blockchain that is promised to be the best ever!
        Aptos!
        <br />
        <br />
        <span className={classes['emphasis']}>Aptos</span> is a move based chain
        with crazy tech that will scale web3 to new paradigms. So why not have
        the best, cutest and a builder orientated community to be pioneers of
        Aptos. Like our forefathers who seeked new lands to build crazy ideas.
        That is what our monkeys do and have already done!
      </p>

      <CollectionCard />

      <div className={classes['team-container']}>
        <h2 className={classes['team-heading']}>
          The Team That Made it Happen!
        </h2>

        <p className={classes['paragraph']}>
          We are a tight knit team of degens focused on trying new shit and
          pushing boundaries.
        </p>

        <Team />
      </div>
    </>
  );
};

export default WhatSection;
