import seed from '../../../assets/images/about/ecosystem-dropdown-seed.webp';
import Echo from '../../icons/echo/Echo';
import Jungle from '../../icons/jungle/Jungle';
import Rafflor from '../../icons/rafflor/Rafflor';
import Tales from '../../../assets/images/about/ecosystem-dropdown-tales-symbol.webp';

export interface DropdownItemModel {
  id: string;
  title: string;
  subtitle: string;
  image: JSX.Element | string;
  desc: string;
  symbol: JSX.Element | string;
  'button-content': string | null;
  link: string;
}

export const dropdown: DropdownItemModel[] = [
  {
    id: 'm1',
    title: '01. $SEEDz',
    subtitle: 'Reward Token',
    desc: "So we created the most degen reward token on Aptos and called it $Seedz. Cause why not? Aren’t we monkeys? Aren't they the forefathers of invention and experimentation? We created seedz to have a halving procedure like our forefather Bitcoin had so that we do know the maximum that will ever be available on the blockchain. 2M Seedz is more than enough to grow the biggest trees for our lovely community.",
    image: (
      <img
        src={seed}
        alt="So we created the most degen reward token on Aptos and called it $Seedz. Cause why not? Aren’t we monkeys? Aren't they the forefathers of invention and experimentation? We created seedz to have a halving procedure like our forefather Bitcoin had so that we do know the maximum that will ever be available on the blockchain. 2M Seedz is more than enough to grow the biggest trees for our lovely community."
      />
    ),
    link: 'https://jungle.aptosmonkeys.club',
    'button-content': 'Get SeedZ',
    symbol: seed,
  },
  {
    id: 'm2',
    title: '02. Jungle',
    subtitle: 'Staking Tool',
    desc: 'We created seeds but then, how are we to disseminate this down to our crazy holders? So we said why not build it? We built the first community reward platform on Aptos, The Jungle,  now used by some of the best projects on Aptos holding more than 1M$ of assets. Stake your monke get some seedz.',
    image: <Jungle />,
    link: 'https://jungle.aptosmonkeys.club',
    'button-content': null,
    symbol: <Jungle />,
  },
  {
    id: 'm3',
    title: '03. Rafflor ',
    subtitle: 'Reward Token',
    desc: 'Then since we are monkeys and hyperactive animals we decided lets create Rafflor; the first NFT raffle system on Aptos.',
    image: <Rafflor />,
    link: 'https://rafflor.aptosmonkeys.club',
    'button-content': null,
    symbol: <Rafflor />,
  },
  {
    id: 'm4',
    title: '04. Echo ',
    subtitle: 'Reward Token',
    desc: 'we said GUYS there’s no p2p markets for people to exchange reward tokens so we said, “Let’s create it ourselves” and Echo was born. P2P swaps of your favourite community tokens can be found there.',
    image: <Echo />,
    link: 'https://echo.aptosmonkeys.club',
    'button-content': null,
    symbol: <Echo />,
  },
  {
    id: 'm5',
    title: '05. Monke Tales',
    subtitle: 'IRL IP Brand',
    desc: 'We wanted to target the masses and we created the cutest Instagram brand called Monke tales to expand the cool IP brand outside of Aptos. Real life relatable memes and merchandise and all the cozy stuff can be found on our instagram.',
    image: <img src={Tales} width='240' height='141' alt='IRL IP Brand' />,
    link: 'https://www.instagram.com/monketales/?hl=en',
    'button-content': null,
    symbol: <img src={Tales} width='240' height='141' alt='IRL IP Brand' />,
  },
];
