import classes from './aptosText.module.scss';

const AptosText = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='49'
      height='12'
      viewBox='0 0 49 12'
      fill='none'
    >
      <g clipPath='url(#clip0_40_2293)'>
        <path
          d='M37.3424 4.04202H36.3382C36.2214 4.04202 36.11 3.99169 36.0323 3.90422L35.6253 3.44441C35.5647 3.37601 35.4772 3.33643 35.3859 3.33643C35.2945 3.33643 35.207 3.37552 35.1464 3.44441L34.7971 3.83924C34.6827 3.96824 34.5185 4.04251 34.346 4.04251H28.8508C28.6944 4.48864 28.5923 4.95969 28.5518 5.44881H33.7396C33.831 5.44881 33.918 5.41168 33.981 5.34571L34.4643 4.84192C34.5244 4.77889 34.608 4.74322 34.6949 4.74322H34.715C34.8063 4.74322 34.8938 4.78231 34.9544 4.85121L35.3614 5.31102C35.4391 5.39848 35.55 5.44881 35.6673 5.44881H39.9019C39.8613 4.95969 39.7592 4.48864 39.6028 4.04251H37.3424V4.04202Z'
          fill='black'
        />
        <path
          d='M31.6917 8.40439C31.7831 8.40439 31.8701 8.36725 31.9331 8.30129L32.4164 7.7975C32.4765 7.73446 32.56 7.69879 32.647 7.69879H32.667C32.7584 7.69879 32.8459 7.73788 32.9065 7.80678L33.3135 8.26659C33.3912 8.35406 33.5021 8.40439 33.6194 8.40439H39.358C39.573 7.96022 39.7303 7.4833 39.8241 6.9844H34.3162C34.1994 6.9844 34.088 6.93407 34.0103 6.84661L33.6033 6.3868C33.5427 6.31839 33.4552 6.27881 33.3638 6.27881C33.2724 6.27881 33.185 6.3179 33.1244 6.3868L32.775 6.78162C32.6607 6.91062 32.4965 6.98489 32.324 6.98489H28.6304C28.7242 7.48428 28.8815 7.9607 29.0965 8.40488H31.6917V8.40439Z'
          fill='black'
        />
        <path
          d='M35.7628 2.50557C35.8541 2.50557 35.9411 2.46844 36.0041 2.40247L36.4874 1.89868C36.5475 1.83565 36.6311 1.79998 36.718 1.79998H36.7381C36.8295 1.79998 36.9169 1.83907 36.9775 1.90797L37.3846 2.36778C37.4622 2.45524 37.5732 2.50557 37.6904 2.50557H38.7816C37.7422 1.12419 36.0892 0.230469 34.2275 0.230469C32.3657 0.230469 30.7127 1.12419 29.6733 2.50557H35.7632H35.7628Z'
          fill='black'
        />
        <path
          d='M33.5704 9.81253H32.0781C31.9614 9.81253 31.8499 9.7622 31.7722 9.67473L31.3652 9.21492C31.3046 9.14651 31.2172 9.10693 31.1258 9.10693C31.0344 9.10693 30.9469 9.14603 30.8863 9.21492L30.537 9.60974C30.4226 9.73874 30.2584 9.81302 30.086 9.81302H30.0625C31.1023 10.9271 32.5824 11.6244 34.2267 11.6244C35.871 11.6244 37.351 10.9271 38.3909 9.81302H33.5704V9.81253Z'
          fill='black'
        />
        <path
          d='M9.04456 11.2559L7.88551 8.4408H2.7074L1.54834 11.2559H0.208496L5.28888 0.598633L10.3844 11.2559H9.04456ZM3.14375 7.37215H7.434L5.28204 2.83171L3.14424 7.37215H3.14375Z'
          fill='black'
        />
        <path
          d='M12.2349 11.2559V0.598633H15.2004C17.5185 0.598633 18.7377 1.66729 18.7377 3.66924C18.7377 5.6712 17.4433 6.71004 15.1853 6.71004H13.4843V11.2559H12.2349ZM13.4843 5.59595H14.8994C16.6908 5.59595 17.4736 5.00909 17.4736 3.66924C17.4736 2.32939 16.6756 1.71224 14.8994 1.71224H13.4843V5.59595Z'
          fill='black'
        />
        <path
          d='M23.2756 1.74254H19.603V0.598633H28.1982V1.74254H24.5251V11.2559H23.2756V1.74254Z'
          fill='black'
        />
        <path
          d='M41.6606 9.31421L42.7366 8.58418L42.8197 8.75716C43.4818 9.96117 44.2045 10.443 45.3035 10.443C46.5226 10.443 47.3807 9.60007 47.3807 8.57637C47.3807 7.61277 46.8989 6.96581 44.9722 6.16786C42.7596 5.24971 42.0066 4.30126 42.0066 2.94675C42.0066 1.47155 43.1657 0.3125 45.1676 0.3125C46.6428 0.3125 47.6514 0.944799 48.2236 1.9685L47.3504 2.5705L47.0943 2.5104C46.6428 1.80285 46.0858 1.3963 45.1373 1.3963C43.9783 1.3963 43.301 2.04375 43.301 2.91646C43.301 3.72907 43.7374 4.19572 45.6191 5.05377C48.0726 6.16786 48.6751 7.08602 48.6751 8.56122C48.6751 10.1718 47.2903 11.5414 45.3181 11.5414C43.6621 11.5414 42.5182 10.9091 41.6602 9.31372L41.6606 9.31421Z'
          fill='black'
        />
      </g>
      <defs>
        <clipPath id='clip0_40_2293'>
          <rect width='48.8639' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AptosText;
