import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.scss';
import Layout from './pages/Layout';
import Home from './pages/home/home';
import About from './pages/about/About';

const { REACT_APP_ROOT } = process.env;

const router = createBrowserRouter([
  {
    path: REACT_APP_ROOT,
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: 'about', element: <About /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
