import { useEffect, useState } from 'react';
import classes from './home.module.scss';
import { characterList } from './home.data';
import { Link } from 'react-router-dom';

const Home = (): JSX.Element => {
  const title1: string = 'FUN COMMUNITY';
  const title2: string = 'CULT';
  const title3: string = 'MOVEMENT';

  const titles: string[][] = [
    title1.split(''),
    title2.split(''),
    title3.split(''),
  ];

  const [title, setTitle] = useState<string[]>(titles.slice()[0]);

  const changeWord = (word: string[]) => {
    const index: number = titles.findIndex(
      (title) => title.join('') === word.join('')
    );

    const nextIndex = index === titles.length - 1 ? 0 : index + 1;
    const { length } = titles[nextIndex];

    while (word.length < length) word.push('A');

    setTitle(word.slice());

    word.forEach((character, idx) => {
      changeLetter(idx, titles[nextIndex][idx] || '');
    });
  };

  const changeLetter = (pos: number, to: string) => {
    let count: number = characterList.findIndex(
      (character) => character === title[pos]
    );

    const interval = setInterval(() => {
      if (characterList[count] > to) {
        setTitle((prevTitle) => {
          const title = prevTitle.slice();
          title[pos] = characterList[count];
          return title.slice();
        });

        count--;
      } else if (characterList[count] < to) {
        setTitle((prevTitle) => {
          const title = prevTitle.slice();
          title[pos] = characterList[count];
          return title.slice();
        });

        count++;
      } else clearInterval(interval);
    }, 50);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      changeWord(title);
    }, 5000);

    return () => clearInterval(interval);
  }, [title]);

  return (
    <div className={classes['main-container']}>
      <div className={classes['container']}>
        <div className={classes['bg-img']}></div>
        <div className={classes['into-container']}>
          <div className={classes['horizontal-line']}>
            <div className={classes['yellow']}></div>
            <div className={classes['orange']}></div>
            <div className={classes['purple']}></div>
            <div className={classes['green']}></div>
          </div>

          <h2 className={classes['title']}>APTOS MONKEY IS A</h2>
          <h2 className={classes['title-special']}>
            {title.map((title, idx) => (
              <span key={idx}>{title}</span>
            ))}
          </h2>
        </div>

        <button className={classes['explore-btn']}>
          <Link to='/about'>Explore The Monkes!</Link>
        </button>
      </div>
    </div>
  );
};

export default Home;
