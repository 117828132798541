import TwitterIcon from '../../icons/twitter/TwitterIcon';
import { MemberModel } from '../team/team.model';
import classes from './Member.module.scss';

const Member = ({ data }: { data: MemberModel }): JSX.Element => {
  return (
    <li className={classes['member']}>
      <div className={classes['img-container']}>
        <img
          src={data.image}
          loading='lazy'
          width='400'
          height='400'
          alt={data.name}
        />
        <a
          href={data.twitter}
          target='_blank'
          className={classes['twitter-link']}
          rel='noreferrer'
        >
          <div className={classes['twitter-logo__container']}>
            <TwitterIcon />
          </div>
        </a>
      </div>
      <div className={classes['content']}>
        <div className={classes['name']}>{data.name}</div>
        <div className={classes['position']}>{data.position}</div>
      </div>
    </li>
  );
};

export default Member;
