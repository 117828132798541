import classes from './CollectionCard.module.scss';
import image from '../../../assets/images/about/what-collection-img.webp';
import AptosText from '../../icons/aptos/aptosText';

const CollectionCard = (): JSX.Element => {
  return (
    <div className={classes['container']}>
      <div className={classes['img-tile__Container']}>
        <h2 className={classes['title']}>The Collection</h2>
        <div className={classes['img-container']}>
          <img src={image} width='512' height='512' alt='Aptos Monkeys pfp' />
        </div>
      </div>

      <div className={classes['content']}>
        <h2 className={classes['title']}>The Collection</h2>
        <p className={classes['value']}>7777</p>

        <h2 className={classes['title']}>Unique Holders</h2>
        <p className={`${classes['value']} ${classes['medium']}`}>3400</p>

        <h2 className={classes['title']}>
          <span>Total Volume - </span>
          <span className={classes['special']}>
            <AptosText />
          </span>
        </h2>
        <p className={`${classes['value']} ${classes['large']}`}>560k+</p>
      </div>
    </div>
  );
};

export default CollectionCard;
