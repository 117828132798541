import { useEffect, useRef } from 'react';
import AboutNav from '../../components/about/nav/AboutNav';
import Footer from '../../components/footer/Footer';
import SeasonCard from '../../components/seasonCard/SeasonCard';
import styles from './About.module.scss';
import EcosystemSection from './ecosystem/Ecosystem';
import FutureSection from './future/Future';
import HoldersSection from './holders/Holders';
import WhatSection from './what/What';

const About = (): JSX.Element => {
  const landingRef = useRef<HTMLTableSectionElement>(null);
  const sectContainerRef = useRef<HTMLDivElement>(null);
  const whatSectionRef = useRef<HTMLTableSectionElement>(null);
  let prevScroll: number = 0;
  let curScroll: number = 0;

  const changeAboutActiveClass = (idx: number) => {
    const nav = document.getElementById('about-nav');
    const anchors = nav?.querySelectorAll('a')!;

    anchors.forEach((anchor) => {
      if (anchor.classList.contains('AboutNav_active__K-UeY')) {
        anchor.classList.remove('AboutNav_active__K-UeY');
      }
    });

    if (!anchors[idx].classList.contains('AboutNav_active__K-UeY')) {
      anchors[idx].classList.add('AboutNav_active__K-UeY');
    }
  };

  const switchActiveClassOnScroll = () => {
    const sections: NodeListOf<HTMLTableSectionElement> =
      document.querySelectorAll(`.${styles['section']}`)!;
    const scrollingUp: boolean = prevScroll > curScroll;

    sections.forEach((section, idx) => {
      const { top, bottom } = section.getBoundingClientRect();
      if (!scrollingUp && top <= 10 && top >= -section.clientHeight) {
        changeAboutActiveClass(idx);
      }

      if (
        scrollingUp &&
        bottom >= window.innerHeight &&
        bottom <= section.clientHeight + window.innerHeight
      ) {
        changeAboutActiveClass(idx);
      }
    });
  };

  const scrollHandler = () => {
    curScroll = window.scrollY;

    switchActiveClassOnScroll();

    prevScroll = curScroll;
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
  }, []);

  return (
    <div className={styles['about-container']}>
      <section id='landing-sect' className={styles['landing']} ref={landingRef}>
        <div className={styles['bg-img']}></div>

        <div className={styles['intro-container']}>
          <div className={styles['horizontal-line']}>
            <div className={styles['yellow']}></div>
            <div className={styles['orange']}></div>
            <div className={styles['purple']}></div>
            <div className={styles['green']}></div>
          </div>

          <div className={styles['title']}>MONKEYS OR MONKES??</div>
        </div>

        <p className={styles['paragraph']}>
          (Tbh, we also get confused) We love funky stuff! yeah the artist Artsi
          is some crazy degen lady who loves “
          <span className={styles['yellow']}>monkes</span>”. So, yes, “monkes”
          is the vibe!
        </p>
      </section>

      <div className={styles['main-content__container']}>
        <div className={styles['content-container']}>
          <aside className={styles['aside']}>
            <AboutNav />
          </aside>
          <div className={styles['section-container']} ref={sectContainerRef}>
            <section
              id='what-sect'
              className={styles['section']}
              ref={whatSectionRef}
            >
              <WhatSection />
            </section>

            <section id='holders-sect' className={styles['section']}>
              <HoldersSection />
            </section>

            <section id='ecosystem-sect' className={styles['section']}>
              <EcosystemSection />
            </section>

            <section id='future-sect' className={styles['section']}>
              <FutureSection />
            </section>
          </div>
        </div>

        <SeasonCard />

        <Footer />
      </div>
    </div>
  );
};

export default About;
