import Article from './Article';
import classes from './Articles.module.scss';
import { articles } from './articles.model';

const Articles = (): JSX.Element => {
  return (
    <div className={classes['article-container']}>
      <ul className={classes['article-list']}>
        {articles.map((article) => (
          <Article key={article.id} article={article} />
        ))}
      </ul>
    </div>
  );
};

export default Articles;
